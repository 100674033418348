<template>
    <a-layout>
        <a-layout-header :class="homeHeader ? 'header home-header' : 'header'">
            <div class="logo">
                <a @click="goHome">
                    <img v-if="homeHeader" src="../../assets/images/logo_white.png" alt="" />
                    <img v-else src="../../assets/images/logo.png" alt="" />
                </a>
            </div>
            <div class="menu-list">
                <a-menu v-model="activeMenu" :border="false" mode="horizontal" class="list">
                    <a-menu-item class="menu" v-for="item in menuList" :key="item.route" @click="goPageTo(item.route)">
                        <i v-if="item.icon" :class="item.icon"></i>
                        <span :class="item.hot ? 'text hot-icon' : 'text'">{{ item.name }}</span>
                    </a-menu-item>
                </a-menu>
            </div>
        </a-layout-header>
    </a-layout>
</template>

<script>
// @ is an alias to /src
// import { getSettingsData } from '@/request/api/home'

export default {
    name: '',
    components: {},

    data() {
        return {
            menuList: [
                { name: '首页', route: 'Index', hide: true },
                { name: '关于我们', route: 'AboutUs', hide: true },
                { name: '产业布局', route: 'Industry', hide: true },
                { name: '国企改革', route: 'CompanyReform', hide: true },
                { name: '企业党建', route: 'PartyBuilding', hide: true },
                { name: '人力资源', route: 'Talents', hide: true },
                { name: '在线留言', route: 'MessageInfo', hide: true, /* hot: 'hot-icon'  */},
            ],
            activeMenu: [''],
            homeHeader: true
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            let routeName = this.$route.name
            this.activeMenu = [routeName]
            if (routeName == 'Index') {
                this.homeHeader = true
            } else {
                this.homeHeader = false
            }
            this.$forceUpdate()
            //如果没有获取过配置数据，初始获取720虚拟体验地址，如果有则从sessionStorage获取
            // this.getSettingsDataList()
        },
        // getSettingsDataList() {
        //     if (sessionStorage.getItem('webSetting')) {
        //         let setting = JSON.parse(sessionStorage.getItem('webSetting'))
        //         console.log(setting)
        //     } else {
        //         getSettingsData().then(res => {
        //             sessionStorage.setItem('webSetting',JSON.stringify(res))
        //         })
        //     }
        // },
        goPageTo(routeName) {
            if (this.activeMenu != routeName) {
                this.$emit('pageName', routeName)
            }
        },
        goHome() {
            if (this.activeMenu[0] != 'Index') {
                this.activeMenu = ['Index']
                this.$emit('pageName', 'Index')
            }
        }
    },
    watch: {
        $route: {
            handler: function (to) {
                console.log(to)
                this.activeMenu = [to.name]
                if (to.name == 'Index') {
                    this.homeHeader = true
                } else {
                    this.homeHeader = false
                }
            },
            // 深度观察监听
            deep: true
        }
    }
}
</script>
<style lang="less" scoped>
.header {
    font-family: 'Microsoft YaHei';
    background: #fff;
    padding: 30px 45px;
    height: 115px;
    display: flex;
    justify-content: space-between;
    .menu-list {
        width: 70%;
        .list {
            display: flex;
            justify-content: flex-end;
        }
        .menu {
            flex: 1;
            position: relative;
        }
        .user-icon {
            width: 25px;
            height: 25px;
            display: inline-block;
            vertical-align: middle;
            background: url(../../assets/images/icon/user.png) no-repeat;
            background-size: contain;
            margin-right: 10px;
        }
        .text {
            vertical-align: middle;
        }
        .user:hover {
            .user-icon {
                background: url(../../assets/images/icon/user_red.png) no-repeat;
                background-size: contain;
            }
        }
        .hot-icon {
            position: relative;
        }
        .hot-icon:after {
            content: '';
            width: 22px;
            height: 12px;
            display: block;
            background: url(../../assets/images/icon/hot-icon.png) no-repeat;
            background-size: contain;
            margin-right: 10px;
            position: absolute;
            top: -15px;
            right: -30px;
        }
    }
    .ant-menu-item,
    .ant-menu-horizontal,
    .ant-menu-item-selected,
    .ant-menu-active,
    .ant-menu-submenu {
        border: none;
    }
    .ant-menu-item {
        color: #333;
        font-size: 16px;
    }
    .ant-menu-active {
        color: #ff721d;
    }
    .ant-menu-item-selected {
        color: #ff721d;
    }
    .ant-menu-item:hover {
        color: #ff721d;
        border: none;
    }
}
.header.home-header {
    width: 100%;
    background: none;
    position: absolute;
    top: 0;
    z-index: 2;
    color: #fff;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    .list {
        background: none;
        .menu {
            .text {
                color: #fff;
            }
            .user-icon {
                background: url(../../assets/images/icon/user_white.png) no-repeat;
                background-size: contain;
            }
            .user:hover {
                .user-icon {
                    background: url(../../assets/images/icon/user_red.png) no-repeat;
                    background-size: contain;
                }
            }
        }
        .menu:hover {
            .text {
                color: #ff721d;
            }
        }
    }
}
</style>
