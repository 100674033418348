<template>
    <a-layout-footer class="footer">
        <div class="footer-box">
            <span class="icon"></span>
            <div class="main">
                <div class="menu-list">
                    <ul>
                        <li v-for="(item, index) in menuList" :key="index" @click="goPage(item.route)">
                            <span>{{ item.name }}</span>
                        </li>
                    </ul>
                </div>
                <div class="info">
                    <div>{{ infoData.copyright }}</div>
                </div>
            </div>
        </div>
    </a-layout-footer>
</template>

<script>
// @ is an alias to /src

export default {
    name: '',
    components: {},
    data() {
        return {
            menuList: [
                { name: '首页', route: 'Index', hide: true },
                { name: '关于我们', route: 'AboutUs', hide: true },
                { name: '产业布局', route: 'Industry', hide: true },
                { name: '国企改革', route: 'CompanyReform', hide: true },
                { name: '企业党建', route: 'PartyBuilding', hide: true },
                { name: '人力资源', route: 'Talents', hide: true },
                { name: '在线留言', route: 'MessageInfo', hide: true /* hot: 'hot-icon'  */ }
            ],
            infoData: {
                copyright: 'Copyright  2017-2022 四川省兴文石海天下奇文化旅游投资有限责任公司版权所有 川公网安备 蜀ICP备13008440号-1 技术支持：中国移动通信集团四川有限公司兴文分公司'
            }
        }
    },
    methods: {
        goPage(name) {
            this.$router.push({ name: name })
        }
    }
}
</script>
<style lang="less" scoped>
.footer {
    padding: 0;
    background: #17191c;
}

.footer-box {
    width: 1300px;
    margin: 0 auto;
    height: 240px;
    color: rgba(255, 255, 255, 0.6);
    position: relative;
    .icon{
        width: 60px;
        height: 80px;
        display: block;
        position: absolute;
        left: 0;
        top: 80px;
        background: url('../../assets/images/icon.png');
    }
    .menu-list {
        width: 1000px;
        margin: 0 auto;
        padding-top: 80px;
        ul {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            li {
                text-align: center;
                position: relative;
                span{
                    cursor: pointer;

                }
            }
            li:after{
                content: '|';
                position: absolute;
                right: -50px;
            }
            li:last-of-type:after{
                content: none;
            }
        }
    }
    .main {
        width: 1200px;
        margin: 0 auto;
        text-align: center;
        position: relative;
        .info {
            width: 100%;
            font-size: 14px;
            color: #666;
            margin-top: 50px;
            .address {
                margin-top: 10px;
            }
        }
        .copy-img {
            margin-left: 20px;
            position: absolute;
            right: 130px;
            top: 50%;
            margin-top: -25px;
        }
    }
}
</style>
