import PcComponent from './routerConfig'
const pcStatic = [
    {
        path: '/index',
        name: 'Index',
        component: PcComponent.Index,
        meta: {
            title: '首页',
        }
    },
    {
        path: '/aboutus',
        name: 'AboutUs',
        component: PcComponent.AboutUs,
        meta: {
            title: '关于我们'
        }
    },
    {
        path: '/industry/:code?/',
        name: 'Industry',
        component: PcComponent.Industry,
        meta: {
            title: '产业布局'
        },
    },
    
    {
        path: '/companyreform/:type?/',
        name: 'CompanyReform',
        component: PcComponent.CompanyReform,
        meta: {
            title: '国企改革'
        }
    },
    {
        path: '/PartyBuilding/:type?/',
        name: 'PartyBuilding',
        component: PcComponent.PartyBuilding,
        meta: {
            title: '企业党建'
        }
    },
    {
        path: '/Talents/:type?/',
        name: 'Talents',
        component: PcComponent.Talents,
        meta: {
            title: '人力资源',
        }
    },
    {
        path: '/messageinfo',
        name: 'MessageInfo',
        component: PcComponent.MessageInfo,
        meta: {
            title: '在线留言',
        }
    },
    {
        path: '/details/:type/:id',
        name: 'Details',
        component: PcComponent.CommonDetails,
        meta: {
            title: '详情'
        },
    },
    
    
]

export { pcStatic}
