<template>
    <div class="warpper">
        <pro-header @pageName="getPageName"></pro-header>
        <router-view></router-view>
        <pro-footer></pro-footer>
    </div>
</template>

<script>
import ProHeader from '@/components/header/index.vue'
import ProFooter from '@/components/footer/index.vue'
import '../../theme/default.less'
export default {
    name: 'Home',
    components: {
        ProHeader,
        ProFooter
    },
    data() {
        return {
            routeName: 'Index'
        }
    },
    methods: {
        getPageName(routeName) {
                this.$router.push({ name: routeName })
        }
    }
}
</script>
<style lang="less" scoped></style>
