import axios from 'axios'
import store from '@/store'
// import storage from 'store'

import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'

// const ACCESS_TOKEN = 'ACCESS_TOKEN'
// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 15000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = error => {
    // console.log(error)
    if (error.response) {
        const data = error.response.data
        // 从 localstorage 获取 token
        var token = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx';
        if(window.sessionStorage.getItem('userInfo')){
            token = JSON.parse(window.sessionStorage.getItem('userInfo')).token;
        }
        if (error.response.status === 403) {
            notification.error({
                message: '禁止访问',
                description: data.message
            })
        }
        if (error.response.status === 401) {
            if (token) {
                store.dispatch('Logout').then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                })
            }
        }
        // console.log('ererere', error.response)
        return Promise.reject(error.response.data)
    }
    return Promise.reject({
        error_code: 1000000,
        error_message: 'UnKnown Error!',
        error_message_zh: '未知错误！',
        status: -1
    })
}

const deleteEmptyArray = data => {
    for (let key in data) {
        let v = data[key]
        if (v instanceof Array) {
            if (v.length === 0) {
                // console.log('------deleted------', key, v)
                delete data[key]
            } else data[key].map(m => deleteEmptyArray(m))
        } else if (v instanceof Object) {
            deleteEmptyArray(data[key])
        }
    }
    return data
}

// request interceptor
request.interceptors.request.use(config => {
    config.headers['SCENERY'] = 20
    // const token = store.getters.accessToken //storage.get(ACCESS_TOKEN)
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdfcGlkIjoiIiwidXNlcl9pZCI6IjEwMDAxMDAwMCIsInVzZXJfbmFtZSI6IiIsIm9yZ19pZCI6bnVsbCwic2NvcGUiOltdLCJwZXJzb25fcGlkIjoiIiwicGVyc29uX25hbWUiOm51bGwsImV4cCI6MTYxMTA4MjE0NCwib3JnX25hbWUiOm51bGwsImp0aSI6IjgzN2MzYWEzLWU4MDktNGFhNi1iMzU5LTA3ZmIzYjM1Mzg0MyIsImNsaWVudF9pZCI6IjgxOTMzMiIsInBlcnNvbl9pZCI6bnVsbH0.w0JntbpSzbeF3vFG8jcUJ92JzauxRwEsBSMx5QhkrGw'
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    var token = ''
    if (window.sessionStorage.getItem('userInfo')) {
        token = JSON.parse(window.sessionStorage.getItem('userInfo')).token
    }
    if (token) {
        config.headers['AUTHORIZATION'] = token
    }
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use(response => {
    // console.log(response)
    if (response.status === 200 || response.data.status === 200) {
        if(response.data.data){
            return deleteEmptyArray(response.data.data)
        }else{
            return response.data
        }
    }else {
        return errorHandler({ response })
    }
    

}, errorHandler)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export { installer as VueAxios, request as axios }
