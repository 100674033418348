import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from '@/layouts/BasicLayout'
// import PhoneLayout from '@/layouts/PhoneLayout'
import { pcStatic } from './pc/static'
// import { mobileStatic } from './mobile/static'
Vue.use(VueRouter)

// console.log(pcStatic)
// 判断是否为移动端
// const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
// console.log(flag)
const rootRouter = [
    {
        path: '/',
        name: 'Root',
        component: BasicLayout,
        redirect: '/index',
        meta: {
            title: '首页'
        },
        children: pcStatic
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    // routes: flag == null ? rootRouter : mobileRouter
    routes: rootRouter
})

export default router
