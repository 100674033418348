/* 公共方法 */
export default{


/*1. 按字数截取字符串 
    @param-word: 传递需要被截取的字符串
    @param-num: 传递需要截取多少个字符串
*/
    splitStr:function(word,num){
        if(!word) return
        let str = word.substring(0,num);
        if(word.length < num){
            str = word.substring(0,num);
        }else{
            str = word.substring(0,num) + '...';
        }
        return str;
    }
















}