import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'
import Antd from 'ant-design-vue'
import moment from 'moment'
import VueFullPage from 'vue-fullpage.js/dist/vue-fullpage'
import common from './units/common/common.js' //公共文件
import './units/fullpage/fullpage.min.css'
import 'ant-design-vue/dist/antd.less'
import animated from 'animate.css'
import { VueAxios } from './request/request'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
var infiniteScroll =  require('vue-infinite-scroll');
Vue.use(infiniteScroll)
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})


import { swiperAnimateCache, swiperAnimate, clearSwiperAnimate } from './units/swiper/swiper.animate.min.js'

// 定义全局的时间过滤器
Vue.filter('dateFormat', function (dateStr, formatStr = 'YYYY-MM-DD HH:mm:ss') {
    // 在过滤器的处理函数中，最后，必须 return 一个东西，这样，才是一个合法的过滤器
    return moment(dateStr).format(formatStr)
})
Vue.prototype.$swiperAnimateCache = swiperAnimateCache
Vue.prototype.$swiperAnimate = swiperAnimate
Vue.prototype.$clearSwiperAnimate = clearSwiperAnimate
router.beforeEach((to, from, next) => {
        // 判断该路由是否需要登录权限
        console.log(to)
        if (to.meta.needToken) {
            // 获取用户登录信息
            if(sessionStorage.getItem('userInfo')){
                next()
            }else{
                next({
                    path: '/login',
                    query: {redirect: to.name}  
                })
            }
        } else {
            next()
        }
   
})
Vue.use(VueAxios)
Vue.use(Antd)
Vue.use(less)
Vue.use(VueFullPage)
Vue.use(animated)
Vue.prototype.common = common
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
