
const PcConfig = {
    /* 首页 */
    Index: () => import('@/views/Index'),
    // 关于我们
    AboutUs: () => import('@/views/pages/AboutUs/Index'),
    // 产业布局
    Industry: () => import('@/views/pages/Industry/Index'),
    // 国企改革
    CompanyReform: () => import('@/views/pages/CompanyReform/Index'),
    // 人力资源
    Talents: () => import('@/views/pages/Talents/Index'),
    // 企业党建
    PartyBuilding: () => import('@/views/pages/PartyBuilding/Index'),
    // 在线留言
    MessageInfo: () => import('@/views/pages/MessageInfo/Index'),
    // 公共详情
    CommonDetails: () => import('@/views/CommonPage/Details/Details'),

}

export default PcConfig
